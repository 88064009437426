export default {
  init() {
    /* eslint-disable */

    function getCookie(cName) {
      const name = cName + "=";
      const cDecoded = decodeURIComponent(document.cookie); //to be careful
      const cArr = cDecoded.split('; ');
      let res;
      cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
      })
      return res;
    }

    function reRunScrollFixes (){
      console.log('running rerun');
      let observer = new IntersectionObserver( (entries, observer) => { entries.forEach(entry => { if(entry.isIntersecting){ entry.target.classList.add('aos-animate'); }else{ entry.target.classList.remove('aos-animate'); } }); }); document.querySelectorAll('[data-aos]').forEach(aosElem => { observer.observe(aosElem) });
            var smoothhome = Scrollbar.init(document.querySelector('#scrollcontainer'),{
              alwaysShowTracks: true,
              renderByPixels: true,
              damping: 0.1
            });
            smoothhome.addListener(function(status) {
              const offset = status.offset;

              if (smoothhome.isVisible(document.getElementById('we-footer'))) {
                $('#home-landing-sidebar').css('position', 'absolute');
                $('#home-landing-sidebar').css('height', '9452.3px');
                $('#home-landing-sidebar').css('bottom', '0px');
                $('#home-landing-sidebar').css('top', 'unset');
              } else {
                $('#home-landing-sidebar').css('position', 'fixed');
                $('#home-landing-sidebar').css('height', '100vh');
                $('#home-landing-sidebar').css('top', offset.y + 'px');
                $('#home-landing-sidebar').css('bottom', 'unset');
              }

              if(smoothhome.isVisible(document.getElementById('home-landing-hero'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='1']").addClass('active');
              } else {
                $("div[data-indi-controller-id='1']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('home-landing-05'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='2']").addClass('active');
              } else {
                $("div[data-indi-controller-id='2']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('parallax-section-5'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='3']").addClass('active');
              } else {
                $("div[data-indi-controller-id='3']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('home-slide03'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='4']").addClass('active');
              } else {
                $("div[data-indi-controller-id='4']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('home-landing-07'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='5']").addClass('active');
              } else {
                $("div[data-indi-controller-id='5']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('parallax-section-3'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='6']").addClass('active');
              } else {
                $("div[data-indi-controller-id='6']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('home-slide06'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='7']").addClass('active');
              } else {
                $("div[data-indi-controller-id='7']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('home-slide07'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='8']").addClass('active');
              } else {
                $("div[data-indi-controller-id='8']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('parallax-section-7'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='9']").addClass('active');
              } else {
                $("div[data-indi-controller-id='9']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('home-landing-04'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='10']").addClass('active');
              } else {
                $("div[data-indi-controller-id='10']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('parallax-section-1'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='11']").addClass('active');
              } else {
                $("div[data-indi-controller-id='11']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('home-landing-02'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='12']").addClass('active');
              } else {
                $("div[data-indi-controller-id='12']").removeClass('active');
              }
            }); /* End scrollbar listener */

            $('div[data-indi-controller-id]').click(function(){
              var scrollindex = $(this).attr('data-indi-controller-id'); //home-landing-05

              if(scrollindex == 1){
                smoothhome.scrollIntoView(document.getElementById('home-landing-hero'));
              }

              if(scrollindex == 2){
                smoothhome.scrollIntoView(document.getElementById('home-landing-05'));
              }

              if(scrollindex == 3){
                smoothhome.scrollIntoView(document.getElementById('parallax-section-5'), {
                  offsetTop: 15
                });
              }
              
              if(scrollindex == 4){
                smoothhome.scrollIntoView(document.getElementById('home-slide03'), {
                  alignToTop: false
                });
              }

              if(scrollindex == 5){
                smoothhome.scrollIntoView(document.getElementById('home-landing-07'), {
                  alignToTop: false
                });
              }

              if(scrollindex == 6){
                smoothhome.scrollIntoView(document.getElementById('parallax-section-3'), {
                  offsetTop: 15
                });
              }

              if(scrollindex == 7){
                smoothhome.scrollIntoView(document.getElementById('home-slide06'), {
                  alignToTop: false
                });
              }

              if(scrollindex == 8){
                smoothhome.scrollIntoView(document.getElementById('home-slide07'), {
                  alignToTop: false
                });
              }

              if(scrollindex == 9){
                smoothhome.scrollIntoView(document.getElementById('parallax-section-7'), {
                  offsetTop: 15
                });
              }

              if(scrollindex == 10){
                smoothhome.scrollIntoView(document.getElementById('home-landing-04'), {
                  offsetTop: 15
                });
              }

              if(scrollindex == 11){
                smoothhome.scrollIntoView(document.getElementById('parallax-section-1'), {
                  offsetTop: 15
                });
              }

              if(scrollindex == 12){
                smoothhome.scrollIntoView(document.getElementById('home-landing-02'));
              }
            }); /** End on click */
            gsap.registerPlugin(ScrollTrigger);

       const scroller = document.querySelector('.smooth-scroll-section');

       const bodyScrollBar = Scrollbar.init(scroller, { damping: 0.03, delegateTo: document, alwaysShowTracks: true });

       ScrollTrigger.scrollerProxy(".smooth-scroll-section", {
         scrollTop(value) {
           if (arguments.length) {
             bodyScrollBar.scrollTop = value;
           }
           return bodyScrollBar.scrollTop;
         }
       });
      
       bodyScrollBar.addListener(ScrollTrigger.update);
      
       ScrollTrigger.defaults({ scroller: scroller });

    const numberOfParallaxes = 8
    const backgroundFactors = [0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03]//[0.03, 0.03, 0.03, 0.03]
    const foregroundFactors = [0.25, 0.25, 0.17, 0.17, 0.17, 0.17, 0.30, 0.30]//[0.25, 0.25, 0.17, 0.30]

    function updateParallaxStyleAttributes() {
        for (let i = 0; i < numberOfParallaxes; i++) {
            let section = document.getElementById('parallax-section-' + i)
            let backgroundParallaxDistance = window.innerHeight * backgroundFactors[i]
            let foregroundParallaxDistance = window.innerHeight * foregroundFactors[i]

            if(window.innerWidth > 992) {
                section.style.setProperty('--parallaxBackground', backgroundParallaxDistance + 'px');
                section.style.setProperty('--parallaxForeground', foregroundParallaxDistance + 'px');
            } else {
                section.style.removeProperty('--parallaxBackground');
                section.style.removeProperty('--parallaxForeground');
            }
        }
    }

    ScrollTrigger.matchMedia({
        "(min-width: 992px)": function() {
            for (let i = 0; i < numberOfParallaxes; i++) {
                let sectionBackgroundTimeline = gsap.timeline({
                    scrollTrigger: {
                        trigger: "#parallax-section-" + i,
                        start: "top bottom",
                        end: "bottom top",
                        scrub: true,
                        invalidateOnRefresh: true
                    }
                });

                let sectionForegroundTimeline = gsap.timeline({
                    scrollTrigger: {
                        trigger: "#parallax-section-" + i,
                        start: "top bottom",
                        end: "bottom top",
                        scrub: true,
                        invalidateOnRefresh: true
                    }
                });

                sectionBackgroundTimeline.to(
                    document.getElementById('background-parallax-' + i),
                    {y: () => {return window.innerHeight * backgroundFactors[i] * -1}, ease: "none"}
                )

                sectionForegroundTimeline.to(
                    document.getElementById('foreground-parallax-' + i),
                    {y: () => {return window.innerHeight * foregroundFactors[i] * -1}, ease: "none"}
                )
            }
        }
    });

    updateParallaxStyleAttributes();

    function windowSizeUpdated() {
        updateParallaxStyleAttributes();
    }

    window.onresize = windowSizeUpdated;
    //end parallax rerun


    }///End Scroll fiexes pfunctions

    AOS.init(); 
    AOS.init({
      duration: 1200, 
      easing: 'ease', 
    });

    /* eslint-disable */

    $(window).on('load', function () {
         AOS.refresh();
         
         if($('#home-landing-sidebar-inner').length){
          //  $(window).impulse({
          //    range: 400,
          //    tempo: 1000,
          //    curb: 3,
          //    effect: 'easeInOutQuad',
          //    delay: true
          //  });
          //$(window).impulse();
            let observer = new IntersectionObserver( (entries, observer) => { entries.forEach(entry => { if(entry.isIntersecting){ entry.target.classList.add('aos-animate'); }else{ entry.target.classList.remove('aos-animate'); } }); }); document.querySelectorAll('[data-aos]').forEach(aosElem => { observer.observe(aosElem) });
            var smoothhome = Scrollbar.init(document.querySelector('#scrollcontainer'),{
              alwaysShowTracks: true,
              renderByPixels: true,
              damping: 0.1
            });

            smoothhome.addListener(function(status) {
              const offset = status.offset;

              if (smoothhome.isVisible(document.getElementById('we-footer'))) {
                $('#home-landing-sidebar').css('position', 'absolute');
                $('#home-landing-sidebar').css('height', '9452.3px');
                $('#home-landing-sidebar').css('bottom', '0px');
                $('#home-landing-sidebar').css('top', 'unset');
              } else {
                $('#home-landing-sidebar').css('position', 'fixed');
                $('#home-landing-sidebar').css('height', '100vh');
                $('#home-landing-sidebar').css('top', offset.y + 'px');
                $('#home-landing-sidebar').css('bottom', 'unset');
              }

              if(smoothhome.isVisible(document.getElementById('home-landing-hero'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='1']").addClass('active');
              } else {
                $("div[data-indi-controller-id='1']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('home-landing-05'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='2']").addClass('active');
              } else {
                $("div[data-indi-controller-id='2']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('parallax-section-5'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='3']").addClass('active');
              } else {
                $("div[data-indi-controller-id='3']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('home-slide03'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='4']").addClass('active');
              } else {
                $("div[data-indi-controller-id='4']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('home-landing-07'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='5']").addClass('active');
              } else {
                $("div[data-indi-controller-id='5']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('parallax-section-3'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='6']").addClass('active');
              } else {
                $("div[data-indi-controller-id='6']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('home-slide06'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='7']").addClass('active');
              } else {
                $("div[data-indi-controller-id='7']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('home-slide07'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='8']").addClass('active');
              } else {
                $("div[data-indi-controller-id='8']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('parallax-section-7'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='9']").addClass('active');
              } else {
                $("div[data-indi-controller-id='9']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('home-landing-04'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='10']").addClass('active');
              } else {
                $("div[data-indi-controller-id='10']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('parallax-section-1'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='11']").addClass('active');
              } else {
                $("div[data-indi-controller-id='11']").removeClass('active');
              }

              if(smoothhome.isVisible(document.getElementById('home-landing-02'))){
                $("div[data-indi-controller-id]").removeClass('active');
                $("div[data-indi-controller-id='12']").addClass('active');
              } else {
                $("div[data-indi-controller-id='12']").removeClass('active');
              }
            }); /* End scrollbar listener */

            $('div[data-indi-controller-id]').click(function(){
              var scrollindex = $(this).attr('data-indi-controller-id'); //home-landing-05

              if(scrollindex == 1){
                smoothhome.scrollIntoView(document.getElementById('home-landing-hero'));
              }

              if(scrollindex == 2){
                smoothhome.scrollIntoView(document.getElementById('home-landing-05'));
              }

              if(scrollindex == 3){
                smoothhome.scrollIntoView(document.getElementById('parallax-section-5'), {
                  offsetTop: 15
                });
              }
              
              if(scrollindex == 4){
                smoothhome.scrollIntoView(document.getElementById('home-slide03'), {
                  alignToTop: false
                });
              }

              if(scrollindex == 5){
                smoothhome.scrollIntoView(document.getElementById('home-landing-07'), {
                  alignToTop: false
                });
              }

              if(scrollindex == 6){
                smoothhome.scrollIntoView(document.getElementById('parallax-section-3'), {
                  offsetTop: 15
                });
              }

              if(scrollindex == 7){
                smoothhome.scrollIntoView(document.getElementById('home-slide06'), {
                  alignToTop: false
                });
              }

              if(scrollindex == 8){
                smoothhome.scrollIntoView(document.getElementById('home-slide07'), {
                  alignToTop: false
                });
              }

              if(scrollindex == 9){
                smoothhome.scrollIntoView(document.getElementById('parallax-section-7'), {
                  offsetTop: 15
                });
              }

              if(scrollindex == 10){
                smoothhome.scrollIntoView(document.getElementById('home-landing-04'), {
                  offsetTop: 15
                });
              }

              if(scrollindex == 11){
                smoothhome.scrollIntoView(document.getElementById('parallax-section-1'), {
                  offsetTop: 15
                });
              }

              if(scrollindex == 12){
                smoothhome.scrollIntoView(document.getElementById('home-landing-02'));
              }
            }); /** End on click */
        }
    }); /* End ON Load */

    $(document).ready(function(){

      /* Remember Accessibility */
      var currentCookie = getCookie('STYXKEY_ACIES');

      if(currentCookie == 'access'){

        $('.accinone').removeClass('d-none');
        $('.accivisible').removeClass('invisible');
        $('#sleek-burger').addClass('animate__animated animate__fadeInLeft');
        $('.accessibility-button').addClass('abactive');
        $('body').addClass('page-accessibility');
        $('body').removeClass('page-visibility');
        $('.home-hero-landing-animated-line').addClass('forcestatic');

        $('.animate__animated').addClass('deanimate');

        $('.animate__animated').each(function() {
          if($(this).hasClass('animate__fadeInUp')) {
            $(this).removeClass('animate__fadeInUp');
            $(this).addClass('markfadeInUp');
          }
          if($(this).hasClass('animate__fadeIn')) {
            $(this).removeClass('animate__fadeIn');
            $(this).addClass('markfadeIn');
          }
        });

        $('[data-aos]').addClass('abstatic');

        $('[data-aos]').each(function() {
          var sanistore = $(this).attr('data-aos');
          $(this).attr('data-storeani', sanistore);
          $(this).attr('data-aos', "");
        });

        $('.parallax-background-main').addClass('absnoparallax');
        $('.parallax-foreground').addClass('absnoparallax');

        
      }
      /* END Remember Accessibility */

      /** Alt Images addition */
      initimg();

      function initimg(){
        var imgs = $('img');
        var i = 0;
        for(i=0;i<imgs.length;i++){
            console.log(imgs[i].getAttribute("alt"));
            if (imgs[i].getAttribute("alt") === null) {
              imgs[i].alt="";
            }
        }
      }

    /** END Alt Images addition */

    /** DLP accessiblity fixes addition */
      $('.dlp-grid-paginate-button').attr('role', 'button');
      $('.dlp-grid-paginate-button').attr('tabindex', '0');
    /** END DLP accessiblity fixes addition */

      $('.slick-wrap').slick({
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              arrows: false,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });

      /* eslint-disable */

      $('.myplink a').click(function(e){
        console.log('manage preferences link');
        e.preventDefault();
        $('a.cmpboxrecalllink').click();
        __cmp("showScreenAdvanced", null, null);
      });

      if($('#home-landing-sidebar-inner').length){
        easyScrollDots({
          'fixedNav': false,
          'fixedNavId': '',
          'fixedNavUpward': false,
          'offset': 0
        });
      }

      $('#sleek-burger-button').click(function(){
        $(this).toggleClass('bopen');
      });

      $('#sleek-burger-button').mouseover(function(){
        $(this).addClass('bintent');
      });

      $('#sleek-burger-button').mouseout(function(){
        $(this).removeClass('bintent');
      });


      $('#weFullMenu').on('shown.bs.modal', function (e) {
        $("#sleek-level-1 > ul > li:nth-child(1) > a").click();
         if($('#scrollcontainer').length){
           //$('#scrollcontainer').addClass('d-none');
           Scrollbar.destroy(document.querySelector('#scrollcontainer'));
        }
      });

      $('#weFullMenu').on('hide.bs.modal', function (e) {
         if($('#scrollcontainer').length){
           //$('#scrollcontainer').removeClass('d-none');
           reRunScrollFixes();
         }
      });

      $('#weFullMenu').on('hide.bs.modal', function (e) {
        $('#sleek-desktop-search').removeClass('animate__animated animate__fadeOut');
        $('#sleek-desktop-search').addClass('d-lg-flex');
        $('#sleek-desktop-search-open').removeClass('animate__animated animate__fadeIn');
        $('#sleek-desktop-search-open').addClass('d-none');
      });

      $('#header-search').click(function(){
        $('#sleek-desktop-search').addClass('animate__animated animate__fadeOut');
        $('#sleek-desktop-search').removeClass('d-lg-flex');
        $('#sleek-desktop-search-open').addClass('animate__animated animate__fadeIn');
        $('#sleek-desktop-search-open').removeClass('d-none');
        $('#desktop-header-search').focus();
      });

      var dotsnav = $('.scroll-indicator-controller').detach();
      $('#home-landing-sidebar-inner').append(dotsnav);
      $('.scroll-indicator-controller').addClass('dots-override');

      $('[data-scroll-indicator-title]').each(function() {
        var sectionnumber = $(this).attr('data-scroll-indicator-title');
        if(sectionnumber == 'indiEnd'){
          console.log('theend');
        } else {
          var tooltext = $(this).attr('data-tooltip-text');
          if(tooltext){ var tooltextinsert = tooltext; } else { var tooltextinsert = "section " + sectionnumber; }
          $("div[data-indi-controller-id='" + sectionnumber +"']").attr('data-toggle', 'tooltip');
          $("div[data-indi-controller-id='" + sectionnumber +"']").attr('title', tooltextinsert);
          $("div[data-indi-controller-id='" + sectionnumber +"']").attr('data-placement', 'right');
          $("div[data-indi-controller-id='" + sectionnumber +"']").attr('data-html', 'true');
          $("div[data-indi-controller-id='" + sectionnumber +"']").attr('data-boundary', 'window');
          $("div[data-indi-controller-id='" + sectionnumber +"']").tooltip();
        }
        
      });
      
      AOS.refreshHard();

      function runHomeAni() {
        const g1 = document.querySelector('#graphicOne');
        const p1 = document.querySelector('#phraseOne');
        g1.classList.remove('d-none');
        g1.classList.add('animate__animated', 'animate__fadeInUp');

        setTimeout(function(){
          p1.classList.remove('invisible');
          p1.classList.add('animate__animated', 'animate__fadeInUp');
        }, 200);

        g1.addEventListener('animationend', () => {
          g1.classList.add('animate__animated', 'animate__fadeOutUp');

          const g2 = document.querySelector('#graphicTwo');
          const p2 = document.querySelector('#phraseTwo');
          g2.classList.remove('d-none');
          g2.classList.add('animate__animated', 'animate__fadeInUp');

          setTimeout(function(){
            p2.classList.remove('invisible');
            p2.classList.add('animate__animated', 'animate__fadeInUp');
          }, 200);

          g2.addEventListener('animationend', () => {
            g2.classList.add('animate__animated', 'animate__fadeOutUp');

            const g3 = document.querySelector('#graphicThree');
            const p3 = document.querySelector('#phraseThree');
            g3.classList.remove('d-none');
            g3.classList.add('animate__animated', 'animate__fadeInUp');

            setTimeout(function(){
              p3.classList.remove('invisible');
              p3.classList.add('animate__animated', 'animate__fadeInUp');
            }, 200);

            g3.addEventListener('animationend', () => {
              g3.classList.add('animate__animated', 'animate__fadeOutUp');

              const g4 = document.querySelector('#graphicFour');
              const p4 = document.querySelector('#phraseFour');
              g4.classList.remove('d-none');
              
              g4.classList.add('animate__animated', 'animate__fadeInUp');

              setTimeout(function(){
                p4.classList.remove('invisible');
                p4.classList.add('animate__animated', 'animate__fadeInUp');
              }, 200);

              setTimeout(function(){
                $('#home-landing-sidebar').removeClass('invisible');
                $('#home-landing-sidebar').addClass('animate__animated animate__fadeInLeft');
                $('#home-landing-hero-cta').removeClass('invisible');
                $('#home-landing-hero-cta').addClass('animate__animated animate__fadeIn');
              }, 1000);

              setTimeout(function(){
                $('#sleek-we').removeClass('invisible');  
                $('#sleek-we').addClass('animate__animated animate__fadeInLeft');
              }, 1500);

              setTimeout(function(){
                $('#sleek-burger').removeClass('invisible');  
                $('#sleek-burger').addClass('animate__animated animate__fadeInLeft');
              }, 2000);
              
              
              //end g4
            });//end g3
          });//end g2
        });//end g1
      }

      if($('#home-landing-sidebar-inner').length){
        if(currentCookie == 'nonaccess'){
          runHomeAni();
        }
      }

      $('ul.dropdown-menu .wchildren.dlevel-1').on('click', function(event) {
          event.preventDefault();
          event.stopPropagation();
          $('ul.dropdown-menu .dlevel-1').siblings('.dropdown-menu').removeClass('show');
          $('ul.dropdown-menu .dlevel-1').removeClass('active');
          $(this).toggleClass('active');
          $(this).siblings('.dropdown-menu').toggleClass('show');
          //$(this).parent().toggleClass('show');
      });
      
    }); //end document ready

    $(window).scroll(function() {    
        var scroll = $(window).scrollTop();
        if($('.home-landing-hero-cta').length){
          $('.home-landing-hero-cta-text').css({'opacity':(( 500-scroll )/500)});
          $('.home-landing-hero-cta-figure').css({'opacity':(( 500-scroll )/500)});
        }
    });

    /* Parallax Insert */
    if($('#home-landing-sidebar-inner').length){

       gsap.registerPlugin(ScrollTrigger);

       const scroller = document.querySelector('.smooth-scroll-section');

       const bodyScrollBar = Scrollbar.init(scroller, { damping: 0.03, delegateTo: document, alwaysShowTracks: true });

       ScrollTrigger.scrollerProxy(".smooth-scroll-section", {
         scrollTop(value) {
           if (arguments.length) {
             bodyScrollBar.scrollTop = value;
           }
           return bodyScrollBar.scrollTop;
         }
       });
      
       bodyScrollBar.addListener(ScrollTrigger.update);
      
       ScrollTrigger.defaults({ scroller: scroller });

    const numberOfParallaxes = 8
    const backgroundFactors = [0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03, 0.03]//[0.03, 0.03, 0.03, 0.03]
    const foregroundFactors = [0.25, 0.25, 0.17, 0.17, 0.17, 0.17, 0.30, 0.30]//[0.25, 0.25, 0.17, 0.30]

    function updateParallaxStyleAttributes() {
        for (let i = 0; i < numberOfParallaxes; i++) {
            let section = document.getElementById('parallax-section-' + i)
            let backgroundParallaxDistance = window.innerHeight * backgroundFactors[i]
            let foregroundParallaxDistance = window.innerHeight * foregroundFactors[i]

            if(window.innerWidth > 992) {
                section.style.setProperty('--parallaxBackground', backgroundParallaxDistance + 'px');
                section.style.setProperty('--parallaxForeground', foregroundParallaxDistance + 'px');
            } else {
                section.style.removeProperty('--parallaxBackground');
                section.style.removeProperty('--parallaxForeground');
            }
        }
    }

    ScrollTrigger.matchMedia({
        "(min-width: 992px)": function() {
            for (let i = 0; i < numberOfParallaxes; i++) {
                let sectionBackgroundTimeline = gsap.timeline({
                    scrollTrigger: {
                        trigger: "#parallax-section-" + i,
                        start: "top bottom",
                        end: "bottom top",
                        scrub: true,
                        invalidateOnRefresh: true
                    }
                });

                let sectionForegroundTimeline = gsap.timeline({
                    scrollTrigger: {
                        trigger: "#parallax-section-" + i,
                        start: "top bottom",
                        end: "bottom top",
                        scrub: true,
                        invalidateOnRefresh: true
                    }
                });

                sectionBackgroundTimeline.to(
                    document.getElementById('background-parallax-' + i),
                    {y: () => {return window.innerHeight * backgroundFactors[i] * -1}, ease: "none"}
                )

                sectionForegroundTimeline.to(
                    document.getElementById('foreground-parallax-' + i),
                    {y: () => {return window.innerHeight * foregroundFactors[i] * -1}, ease: "none"}
                )
            }
        }
    });

    updateParallaxStyleAttributes();

    function windowSizeUpdated() {
        updateParallaxStyleAttributes();
    }

    window.onresize = windowSizeUpdated;
  }
    /* End Parallax */


  /* 6 Sense */
  $(document).ready(function(){
    console.log('6 Sense function loaded');

    var datattype = $('#pagemetadata').data("contenttype");
    var targetaudience = $('#pagemetadata').data("targetaudience");
    var databuystage = $('#pagemetadata').data("buystage");
    var persona = $('#pagemetadata').data("persona");
    var primaryproduct = $('#pagemetadata').data("primaryproduct");
    var secondary_p = $('#pagemetadata').data("secondary_product");
    var datsol = $('#pagemetadata').data("solution");//buystage primary product now
    var vertical = $('#pagemetadata').data("vertical");
  
    window._6si = window._6si || [];
  
    window._6si.push(['setPageAttributes', {
  
      primary_product_solution: datsol,//data-solution was product_type now primary_product
      secondary_product: secondary_p,
      asset_type: datattype, //data-assettype
      journey_stage: databuystage,//databuystage
      target_audience : targetaudience,
      persona : persona,
      primary_product : primaryproduct,
      industry_vertical : vertical
  
    }]);
  
    console.log(_6si);
   
  }); 

   $(document).on("focusin", "#hidden-close", function() {
      console.log('focused on');
       $('#hidden-close').blur();
       $('#sleek-burger-button').focus(); 
       setTimeout(function() { 
         $('#sleek-burger-button').focus(); 
       }, 100);
   });

  //  $(document).on("focusin", "#sleek-burger-button", function() {
  //   $('#sleek-burger-button').toggleClass('buttonfocus');
  //   console.log('tt');
  //  });
  /* End 6 Sense */
    // $(document).on("focusin", "a ,button", function() {
    //   console.log('focused');
    //   var $focused = $(':focus');
    //   console.log($focused);
    // });

    $('.accessibility-button').click(function(e){
      e.preventDefault();

      var scurrentCookie = getCookie('STYXKEY_ACIES');
      console.log('scookie is : ' + scurrentCookie);

      $('.accessibility-button').toggleClass('abactive');
      $('body').toggleClass('page-accessibility');
      $('.home-hero-landing-animated-line').toggleClass('forcestatic');

      $('.animate__animated').toggleClass('deanimate');

      $('.animate__animated').each(function() {
        if($(this).hasClass('deanimate')) {
          if($(this).hasClass('animate__fadeInUp')) {
            $(this).removeClass('animate__fadeInUp');
            $(this).addClass('markfadeInUp');
          }
          if($(this).hasClass('animate__fadeIn')) {
            $(this).removeClass('animate__fadeIn');
            $(this).addClass('markfadeIn');
          }
        } else {
          if($(this).hasClass('markfadeInUp')) {
            $(this).removeClass('markfadeInUp');
            $(this).addClass('animate__fadeInUp');
          }
          if($(this).hasClass('markfadeIn')) {
            $(this).removeClass('markfadeIn');
            $(this).addClass('animate__fadeIn');
          }
        }
      });


      $('[data-aos]').toggleClass('abstatic');

      $('[data-aos]').each(function() {
        var anistore = $(this).attr('data-aos');
        //console.log('anistore: ' + anistore);
        //console.log($(this));

        if($(this).hasClass('abstatic')) {
          
          $(this).attr('data-storeani', anistore);
          $(this).attr('data-aos', "");
          //console.log('must create');
          
        } else {
          var aniback = $(this).attr('data-storeani');
          if(aniback){
            $(this).attr('data-aos', aniback);
            //console.log('aniback: ' + aniback);
          }
        }
      });

      $('.parallax-background-main').toggleClass('absnoparallax');
      $('.parallax-foreground').toggleClass('absnoparallax');

      if(scurrentCookie == 'nonaccess'){
        var date = new Date();
        date.setTime(date.getTime() + (30 * 24 * 60 * 60 *1000));
        var cexpires = date.toGMTString();
        document.cookie = "STYXKEY_ACIES=access;expires=" + cexpires + ";path=/";
      } else {
        var date = new Date();
        date.setTime(date.getTime() + (30 * 24 * 60 * 60 *1000));
        var cexpires = date.toGMTString();
        document.cookie = "STYXKEY_ACIES=nonaccess;expires=" + cexpires + ";path=/";
      }


    });

    
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired


  },
};
