export default {
    init() {
      // JavaScript to be fired on the home page

      $(document).ready(function(){
          console.log('home landing!!');
      });
    },
    finalize() {
      // JavaScript to be fired on the home page, after the init JS
    },
  };